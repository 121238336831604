import React from 'react';
type DropdownItemProps = {
    /**
     * Value of the drop down item
     **/
    value?: string;
    selected?: boolean;
    children?: string;
    onClick?: React.MouseEventHandler<HTMLLIElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLLIElement>;
};

export const DropdownItem: React.FC<DropdownItemProps> = ({ value, selected, onClick, children, ...props }: DropdownItemProps): React.ReactElement => {
    return (
        <li className={'flos-dropdown-list-item'} role={'option'} tabIndex={0} value={value} aria-selected={selected} onClick={onClick} {...props}>
            {children}
        </li>
    );
};

export default DropdownItem;

export type { DropdownItemProps };
