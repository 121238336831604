import React from 'react';
import cx from 'classnames';
import { TooltipIcon } from '../../../core/forms/tooltip-icon/tooltip-icon';

type BoxProps = {
    title?: string | React.ReactElement;
    toolTipContent?: React.ReactNode;
    actionArea?: React.ReactNode;
    children?: React.ReactNode;
    padding?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
    theme?: 'primary' | 'secondary' | 'highlight' | 'transparent';
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>;

const ConditionalWrapper = ({ children, condition, wrapper }: any) => (condition ? wrapper(children) : children);

const Box = ({ title, padding = 'medium', theme = 'primary', className, toolTipContent, actionArea, children, ...props }: BoxProps): React.ReactElement => {
    return (
        <div
            className={cx(
                {
                    'flos-box': true,
                    [`flos-box--theme-${theme}`]: !!theme,
                    'u-spacing-inset-s': padding === 'small',
                    'u-spacing-inset-l': padding === 'large',
                    'u-spacing-inset-xl': padding === 'xlarge',
                    'u-spacing-inset-xxl': padding === 'xxlarge',
                    'flos-box--flex': title || toolTipContent || actionArea,
                    'flos-box--has-title': !!title,
                    'flos-box--has-tooltip': !!toolTipContent,
                    'flos-box--has-action-area': !!actionArea,
                },
                className
            )}
            {...props}
        >
            <ConditionalWrapper condition={title && toolTipContent} wrapper={(children: any) => <div className={'flos-box-header'}>{children}</div>}>
                {title && (
                    <div
                        className={cx('h6', 'flos-box-title', {
                            'flos-box-offset-s-top-left': padding === 'small',
                            'flos-box-offset-m-top-left': padding === 'medium',
                            'flos-box-offset-l-top-left': padding === 'large',
                            'flos-box-offset-xl-top-left': padding === 'xlarge',
                            'flos-box-offset-xxl-top-left': padding === 'xxlarge',
                        })}
                    >
                        {title}
                    </div>
                )}
                {toolTipContent && (
                    <div
                        className={cx('flos-box-tooltip', {
                            'flos-box-offset-s-top-right': padding === 'small',
                            'flos-box-offset-m-top-right': padding === 'medium',
                            'flos-box-offset-l-top-right': padding === 'large',
                            'flos-box-offset-xl-top-right': padding === 'xlarge',
                            'flos-box-offset-xxl-top-right': padding === 'xxlarge',
                        })}
                    >
                        <TooltipIcon content={toolTipContent} />
                    </div>
                )}
            </ConditionalWrapper>
            {children}
            {actionArea && (
                <div
                    className={cx('flos-box-action-area', {
                        'flos-box-offset-s-bottom-right': padding === 'small',
                        'flos-box-offset-m-bottom-right': padding === 'medium',
                        'flos-box-offset-l-bottom-right': padding === 'large',
                        'flos-box-offset-xl-bottom-right': padding === 'xlarge',
                        'flos-box-offset-xxl-bottom-right': padding === 'xxlarge',
                    })}
                >
                    {actionArea}
                </div>
            )}
        </div>
    );
};

export type { BoxProps };
export { Box };
export default Box;
