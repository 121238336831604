import React, { useEffect } from 'react';
import cx from 'classnames';
import { normalizeOption, normalizeOptionGroup, OptionProps, OptionGroupProps } from '../select-input';
import FlosField, { FlosFieldProps } from '../../flos-field';
import { FlosInput, FlosInputProps } from '../../input/flos-input';
import { SuggestionsList } from './typeahead-suggestion-list';

type TypeaheadFieldProps = {
    /**
     * options that will be rendered as children, you can also construct your option markup as children
     */
    options: OptionProps[] | string[];
    value?: string;
    /**
     * Invoked whenever items are added or removed. Receives an array of the
     * selected options.
     */
    // onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & FlosInputProps &
    FlosFieldProps &
    Omit<React.ComponentPropsWithRef<'input'>, 'onBlur'>;

const findOptionByValue = (options: OptionProps[], value: string) => {
    if (value) {
        let findOption = options.find((option: OptionProps) => option.value.toLocaleLowerCase() === value.toLocaleLowerCase());
        return findOption ? findOption : '';
    } else {
        return '';
    }
};

const buildOptions = (options: Array<OptionProps | string>) => {
    const filteredOptions = options ? options.map(normalizeOption) : [];
    return normalizeOptionGroup(filteredOptions);
};

const filterOptions = (options: OptionGroupProps, value: string) => {
    let newOptionGroups: OptionGroupProps = [];
    for (const key in options) {
        if (options.hasOwnProperty(key)) {
            if (isNaN(parseInt(key))) {
                // Option groups will be a string
                if (!newOptionGroups[key]) {
                    newOptionGroups[key] = [];
                }
                newOptionGroups[key] = options[key].filter((option: OptionProps) => option.label.toLowerCase().indexOf(value.toLowerCase()) > -1);
            } else {
                options[key].label.toLowerCase().indexOf(value.toLowerCase()) > -1 && newOptionGroups.push(options[key]);
            }
        }
    }
    return newOptionGroups;
};

export const TypeaheadField = React.forwardRef<HTMLInputElement, Omit<TypeaheadFieldProps, 'maxLength'>>(
    ({ value, isValid, onValidityChange, options: originalOptions, label, iconShape, errorText, helpText, ...props }, ref: React.RefObject<HTMLInputElement>) => {
        const usePrevious = (value: any) => {
            const ref = React.useRef();
            React.useEffect(() => {
                ref.current = value;
            });
            return ref.current;
        };

        const internalOptions = originalOptions ? buildOptions(originalOptions) : [];
        const [options, setOptions] = React.useState<OptionProps[]>(internalOptions);

        const inputRef = React.createRef<HTMLInputElement>();
        const displayInputRef = ref || React.createRef<HTMLInputElement>();
        const wrapperRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
        const [showOptions, setShowOptions] = React.useState<boolean>(false);
        const [internalValue, setInternalValue] = React.useState<string>('');
        const [isTyping, setIsTyping] = React.useState<boolean>(true);
        const previousValue = usePrevious(value);
        const [isValidLocal, setIsValidLocal] = React.useState<boolean | undefined>(isValid);

        const arrowUpDownAction = (event: any, upAction: boolean) => {
            if (!showOptions) return;
            // Gets all available options
            const suggestions = wrapperRef.current?.getElementsByClassName('typeaheadfield-suggestionlist-option-key');
            const firstSuggestion = suggestions[0];
            const lastSuggestion = suggestions[suggestions.length - 1];
            let activeIndex = -1;
            [...suggestions].forEach((elem, index) => {
                if (elem === document.activeElement) {
                    activeIndex = index;
                }
            });
            if (upAction) {
                if (activeIndex === -1 || activeIndex === 0) {
                    (lastSuggestion as HTMLElement).focus();
                } else {
                    const currentActiveItem = suggestions[activeIndex - 1];
                    (currentActiveItem as HTMLElement).focus();
                }
            } else {
                if (activeIndex === -1 || activeIndex === suggestions.length - 1) {
                    (firstSuggestion as HTMLElement).focus();
                } else {
                    const currentActiveItem = suggestions[activeIndex + 1];
                    (currentActiveItem as HTMLElement).focus();
                }
            }
            event.preventDefault();
        };

        React.useEffect(() => {
            const keyListener = (event: any) => {
                const key = event.key;
                if (key == 'ArrowUp' || key == 'ArrowDown') {
                    // Prevent input cursor from going to the beginning when pressing up.
                    event.preventDefault();
                    arrowUpDownAction(event, key == 'ArrowUp' ? true : false);
                }
                if (key == 'Tab') {
                    // Not tab event as dropdown doesnt have tab accessibility
                    // handleTab(event);
                }
                if (key == 'Escape') {
                    setShowOptions(false);
                    if (!!displayInputRef.current) {
                        displayInputRef.current.blur();
                    }
                }
                if (key == 'Space') {
                    if (document.activeElement == inputRef.current) onFocusHandler(event);
                }
                if (event.key == 'Backspace' && event.target.value) {
                    if (document.activeElement == displayInputRef.current) {
                        setInternalValue(event.target.value);
                        setOptions(filterOptions(internalOptions, event.target.value));
                    }
                }
                if (event.key == 'Enter' && showOptions) {
                    // event.preventDefault();
                    let option = options.find((opt) => opt.value === event.target.value);
                    option && onClickHandler(option);
                }
            };

            const onClickOutsideHandler = (event: MouseEvent) => {
                if (wrapperRef && !wrapperRef.current?.contains(event.target as Node)) {
                    setShowOptions(false);
                    setOptions(internalOptions);
                }
            };

            if (internalValue !== previousValue && !isTyping) {
                let extractedOption: any = findOptionByValue(originalOptions ? originalOptions.map(normalizeOption) : [], value ? value.toString() : '');
                extractedOption?.label && setInternalValue(extractedOption.label);
                setIsTyping(false);
            }

            window.addEventListener('mousedown', onClickOutsideHandler);
            window.addEventListener('keydown', keyListener);

            return () => {
                window.removeEventListener('mousedown', onClickOutsideHandler);
                window.removeEventListener('keydown', keyListener);
            };
        }, [wrapperRef, showOptions, internalValue]);

        useEffect(() => {
            setIsValidLocal(isValid);
        }, [isValid]);

        useEffect(() => {
            setInternalValue(value ? (value as string) : '');
            const isValidValue = validatedValue(value);
            value && setIsValidLocal(!!isValidValue);
        }, [value]);

        const validatedValue = (valueToValidate: any) => {
            const extractedOption = findOptionByValue(originalOptions ? originalOptions.map(normalizeOption) : [], valueToValidate);
            if (extractedOption) {
                setInternalValue(extractedOption ? extractedOption.label : '');
                setShowOptions(false);
            }
            return extractedOption;
        };

        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            setShowOptions(true);
            setInternalValue(newValue);
            setOptions(newValue ? filterOptions(internalOptions, newValue) : internalOptions);
        };

        const onChangeHiddenHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            const isValidValue = validatedValue(newValue);
            isValidValue && props.onChange && props.onChange(event);
        };

        const onClickHandler = (option: OptionProps) => {
            setInternalValue(option.label);
            setOptions(filterOptions(internalOptions, option.value));
            setIsValidLocal(true);
            setShowOptions(false);
            if (inputRef.current) {
                Object.getOwnPropertyDescriptor((window as any).HTMLInputElement.prototype, 'value')?.set?.call(inputRef.current, option.value);
                inputRef.current?.dispatchEvent(new Event('input', { bubbles: true }));
            }
        };

        const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            setShowOptions(true);
            if (newValue === '') {
                setOptions(internalOptions);
            } else {
                setOptions(filterOptions(internalOptions, newValue));
            }
            props.onFocus && props.onFocus(event);
        };

        const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            const isValidValue = validatedValue(newValue);
            newValue && setIsValidLocal(!!isValidValue);
        };

        return (
            <div className={cx('typeaheadfield', showOptions && 'is-open')} ref={wrapperRef}>
                <FlosField
                    ref={ref}
                    onValidityChange={onValidityChange}
                    isValid={isValidLocal}
                    label={label}
                    iconShape={iconShape}
                    errorText={errorText}
                    required={props.required}
                    hasDropdown={true}
                    id={props.id}
                    helpText={helpText}
                    disabled={props.disabled}
                    renderInput={({ getInputProps }) => (
                        <>
                            <FlosInput
                                ref={displayInputRef}
                                {...getInputProps({ ...props, onChange: onChangeHandler, onFocus: onFocusHandler, onBlur: onBlurHandler })}
                                value={internalValue}
                                autoComplete={'off'}
                            />
                            <SuggestionsList isOpen={showOptions} onClick={onClickHandler} options={options} />
                        </>
                    )}
                />

                <input onChange={onChangeHiddenHandler} className="visuallyhidden" ref={inputRef} />
            </div>
        );
    }
);
TypeaheadField.displayName = 'TypeaheadField';
export type { TypeaheadFieldProps };
export default TypeaheadField;
