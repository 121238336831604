import React from 'react';
import cx from 'classnames';

type IListBoxInfoProps = {
    /** Info area label */
    label?: string;
    /** Info area value */
    text?: string;
    /** Info area addon label */
    addonLabel?: string;
    /** Info area addonvalue */
    addonText?: string;
    /** If you need to add a icon */
    icon?: React.ReactNode;
    /** Use as children. They will override the title and text */
    children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;
const ListBoxInfo = ({ label, text, icon, addonLabel, addonText }: IListBoxInfoProps) => {
    return (
        <div className={cx('flos-list-box-info')}>
            {icon && icon}
            <div className="u-flex-box u-flex-box--col u-flex-box--center u-flex-box--justify-center">
                <div className="u-flex-box u-flex-box--center u-flex-box--justify-center">
                    {label && <p className="small">{label}</p>}
                    {text && <h6>{text}</h6>}
                </div>
                {addonLabel && addonText && (
                    <div className="u-flex-box u-flex-box--center u-flex-box--justify-center">
                        {addonLabel && <p className="small">{addonLabel}</p>}
                        {addonText && <h6>{addonText}</h6>}
                    </div>
                )}
            </div>
        </div>
    );
};

export type { IListBoxInfoProps };
export { ListBoxInfo };
export default ListBoxInfo;
