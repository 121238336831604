import cx from 'classnames';
import React from 'react';
import { changeBodyClass } from '../../../utils/dom';
import { callAll } from '../../../utils/fp';
import { useId } from '../../../utils/hooks';

const backdrop = (function () {
    const backdropEl = document.createElement('div');
    backdropEl.className = 'modal-backdrop fade';
    return backdropEl;
})();

const toggleBackdrop = (show: boolean) => {
    backdrop.className = show ? 'modal-backdrop fade in' : 'modal-backdrop fade';
};

const adjustZIndex = (zIndex: string) => {
    backdrop.style.zIndex = (parseInt(zIndex) - 1).toString();
};

const mountedOverlay: string[] = [];
const mountedBackdrop: string[] = [];

const addInstance = (instanceId: string, isFullScreen?: boolean, zIndexStyle?: string) => {
    if (mountedOverlay.length === 0 && document && document.body) {
        changeBodyClass('modal-open', 'add');
    }
    if (document && document.body && !isFullScreen) {
        if (zIndexStyle) {
            adjustZIndex(zIndexStyle);
        }
        mountedBackdrop.push(instanceId);
        document.body.appendChild(backdrop);
        toggleBackdrop(true);
    }
    mountedOverlay.push(instanceId);
};

const removeInstance = (instanceId: string, isFullScreen?: boolean) => {
    const instanceIndex = mountedOverlay.indexOf(instanceId);

    if (instanceIndex !== -1) {
        mountedOverlay.splice(instanceIndex, 1);
        if (!isFullScreen) {
            mountedBackdrop.splice(mountedBackdrop.indexOf(instanceId), 1);
        }

        if (document && document.body) {
            if (mountedOverlay.length === 0) {
                changeBodyClass('modal-open', 'remove');
            }
            if (document.body.querySelector('.modal-backdrop')) {
                if (!isFullScreen && mountedBackdrop.length === 0) {
                    toggleBackdrop(false);
                    document.body.removeChild(backdrop);
                }
            }
        }
    }
};

interface IModalOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
    isOpen: boolean;
    onDismiss?: () => void;
    isFullScreen?: boolean;
}

/**
 * `ModalOverlay` add the semi-transparent overlay behind `Modal`.
 * When isOpen, it will:
 * - add "modal-open" class to `body`
 * - append a backdrop `div` as child of `body`
 */
export const ModalOverlay: React.FC<IModalOverlayProps> = (props) => {
    const { isOpen, onDismiss, className, onClick, onKeyDown, style, id, isFullScreen, ...restProps } = props;
    const instanceId = useId(id);
    const [calculatedStyle, setCalculatedStyle] = React.useState({ ...style, display: 'none' });

    React.useEffect(() => {
        if (isOpen) {
            addInstance(instanceId, isFullScreen, props.style?.zIndex?.toString());
            setCalculatedStyle({ ...style, display: 'block' });
        } else {
            setCalculatedStyle({ ...style, display: 'none' });
            removeInstance(instanceId, isFullScreen);
        }
        return () => {
            removeInstance(instanceId, isFullScreen);
        };
    }, [isOpen, instanceId]);

    return (
        <div
            className={cx('modal', isOpen && (isFullScreen ? 'slide-in' : 'in'), !isOpen && isFullScreen && 'slide-out', className)}
            role="dialog"
            aria-hidden={!isOpen}
            onClick={callAll(onClick, (ev) => {
                ev.stopPropagation();
                onDismiss && onDismiss();
            })}
            onKeyDown={callAll(onKeyDown, (ev) => {
                if (ev.key === 'Escape') {
                    ev.stopPropagation();
                    onDismiss && onDismiss();
                }
            })}
            tabIndex={0}
            style={calculatedStyle}
            id={instanceId}
            {...restProps}
        />
    );
};

export default ModalOverlay;
