import { FileNameType, IFile, ErrorType, IFileError } from './types';

export const splitFileExtension = (fileName: FileNameType) => {
    const extension = fileName.split('.').pop();
    const nameString = fileName.replace(`.${extension}`, '');
    return { nameString, extension };
};

export const isFileExist = (fileName: FileNameType, files: IFile[]) => files.some((file) => file.key === fileName);
export const isErrorExist = (fileName: FileNameType, errors: IFileError[]) => {
    const count = errors.filter((err) => err.fileName === fileName).length;
    return count > 0;
};

export const getUniqueFileName = (fileName: FileNameType, files: IFile[]) => {
    // fileName format, e.g. sample.pdf, sample(1).pdf, sample(2).pdf
    let count = files.filter((file) => file.key === fileName).length;
    if (count === 0) return fileName;

    const { nameString, extension } = splitFileExtension(fileName);
    let newNameString = nameString;
    const regex = /\((\d+)\)/;
    const match = newNameString.match(regex);

    if (match) {
        count = parseInt(match[1]);
        count++;
        newNameString = newNameString.replace(/\([0-9]\)/, '');
    }

    let uniqueFileName = `${newNameString}(${count}).${extension}`;
    while (isFileExist(uniqueFileName, files)) {
        uniqueFileName = getUniqueFileName(uniqueFileName, files);
    }

    return uniqueFileName;
};

export const getTooSmallRejectionErr = (fileName: FileNameType, minUploadFileSize: number): IFileError => {
    return {
        fileName,
        error: 'FILE_TOO_SMALL' as ErrorType,
        metaData: { minUploadFileSize },
    };
};

export const getTooLargeRejectionErr = (fileName: FileNameType, maxUploadFileSizeInMb: number): IFileError => {
    return {
        fileName,
        error: 'FILE_TOO_LARGE' as ErrorType,
        metaData: { maxUploadFileSizeInMb },
    };
};

export const getInfectedRejectionErr = (fileName: FileNameType): IFileError => {
    return {
        fileName,
        error: 'FILE_IS_INFECTED' as ErrorType,
    };
};

export const getTooManyFilesRejectionErr = (fileName: FileNameType, totalFilesLimit = 10): IFileError => {
    return {
        fileName,
        error: 'TOO_MANY_FILES' as ErrorType,
        metaData: { totalFilesLimit },
    };
};

export const getInvalidTypeRejectionErr = (fileName: FileNameType): IFileError => {
    return {
        fileName,
        error: 'FILE_INVALID_TYPE' as ErrorType,
    };
};

export const getServiceRejectionErr = (fileName: FileNameType, errMessage: string, serviceName = ''): IFileError => {
    return {
        fileName,
        error: 'SERVICE_ERROR' as ErrorType,
        metaData: {
            serviceName: serviceName,
            message: errMessage,
        },
    };
};

export const isDefined = (value: any) => {
    return value !== undefined && value !== null;
};
