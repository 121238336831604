import cx from 'classnames';
import React from 'react';
import { FlosInputProps } from '../../input/flos-input';
import { FlosField, FlosFieldProps } from '../../flos-field';
import { callAll } from '../../../../utils/fp';

export type CheckboxGroupProps = {
    label?: React.ReactNode;
    description?: React.ReactNode;
    /** error help text for the field that will only be displayed if status is error */
    errorText?: string;
    /**
     * states if the checkboxes should be arranged vertically
     * @default true
     */
    stacked?: boolean;
    isValid?: boolean;
    required?: boolean;
    /**
     * callback with array of checked values
     */
    onChangeValue?: (value: Array<string | number | null>) => void;
    children?: React.ReactNode;
    disabled?: boolean;
} & FlosInputProps &
    Omit<FlosFieldProps, 'maxLength' | 'value' | 'iconShape' | 'editable'>;

export const CheckboxGroup = React.forwardRef<HTMLInputElement, CheckboxGroupProps>(
    ({ onChangeValue, label, description, errorText, stacked = true, children, isValid, required, disabled, helpText, className, ...rest }, ref) => {
        const [values, setValues] = React.useState<string[]>(() => {
            return React.Children.toArray(children)
                .map((child) => {
                    if (React.isValidElement(child) && ['FlosCheckbox', 'CheckboxField'].includes((child.type as any).displayName) && child.props.checked) {
                        return child.props.value;
                    }
                })
                .filter(Boolean);
        });

        const changeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            return setValues((prevValues) => {
                if (prevValues.indexOf(value) === -1 && event.target.checked) {
                    return [...prevValues, value];
                } else {
                    return [...prevValues.filter((val: string) => val !== value)];
                }
            });
        };

        React.useEffect(() => {
            onChangeValue && onChangeValue(values);
        }, [values]);

        return (
            <fieldset className={className}>
                {label && (
                    <legend>
                        <strong>
                            {label}
                            {required && '*'}
                        </strong>
                    </legend>
                )}
                {description && <p>{description}</p>}
                <FlosField
                    ref={ref}
                    custom
                    required={required}
                    isValid={isValid}
                    disabled={disabled}
                    errorText={errorText}
                    helpText={helpText}
                    {...rest}
                    renderInput={() => (
                        <div className={cx('flos-checkbox-group', stacked && 'flos-checkbox-group--stacked')}>
                            {React.Children.map(children, (child, index) => {
                                if (React.isValidElement(child)) {
                                    const checked = values.indexOf(child.props.value) > -1;
                                    return React.cloneElement(child as React.ReactElement, {
                                        onChange: callAll(child.props.onChange, changeValues),
                                        key: `checkbox-group-child-${child.props.value || index}`,
                                        disabled: child.props.disabled || disabled,
                                        isValid: isValid,
                                        checked,
                                    });
                                } else {
                                    return;
                                }
                            })}
                        </div>
                    )}
                />
            </fieldset>
        );
    }
);
CheckboxGroup.displayName = 'CheckboxGroup';
