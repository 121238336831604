import React from 'react';
import cx from 'classnames';

type SidebarProps = {
    /** Adds classes to show hide sidebar   */
    isOpen?: boolean;
} & React.ComponentPropsWithRef<'div'>;

export const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
    return (
        <>
            <div ref={ref} className={cx('site-header-sidebar', props.isOpen ? 'is-visible' : 'is-hidden', props.className && props.className)}>
                {props.children}
            </div>
            <div className={cx('site-header-sidebar-backdrop', props.isOpen ? 'is-visible' : 'is-hidden')} />
        </>
    );
});

Sidebar.displayName = 'Sidebar';

export type { SidebarProps };
export default Sidebar;
