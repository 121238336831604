import * as React from 'react';
import { IFile, IUploadServiceContext, UploadFieldProps } from '.';
import cx from 'classnames';
import { UploadServiceContext } from './upload-service-provider';
import { Button, Message } from '../../..';
import { ScanFileList } from './scan-file-list';

export const UploadField: React.FC<UploadFieldProps> = ({
    acceptedExtensions = [],
    configLoading,
    deleteButtonLabel,
    description,
    errorMessages,
    errors,
    fileStateLoading,
    label,
    onError,
    onFileChange,
    onFileCount = (count) => (count ? `Attached ${count} file${count > 1 ? 's' : ''}` : ''),
    onFileDelete,
    onInit = () => null,
    onScan,
    scannedFiles,
    uploadButtonLabel,
    uploadLoading,
}) => {
    // const [_rejectedFiles, setRejectedFiles] = React.useState<IFile[]>([]);
    const initRender = React.useRef(true);
    const fileSelectorRef = React.useRef<HTMLInputElement>(null);

    // Handle when user click to open the file selector
    const handleFileSelect = React.useCallback(() => fileSelectorRef.current?.click(), [fileSelectorRef]);
    // onError callback
    React.useEffect(() => {
        if (errors.length) onError(errors);
    }, [errors, onError]);
    // onInit and onScan callback
    React.useEffect(() => {
        if (initRender.current && onInit) {
            initRender.current = false;
            onInit();
            return; // ? skip callback if is initial render
        } else if (!initRender.current && onScan) {
            onScan(scannedFiles);
        }
    }, [scannedFiles]);
    const hasFilePending = scannedFiles.findIndex((file: IFile) => file.scanStatus === 'pending') >= 0;
    // create file counter label text
    const fileCounter = !onFileCount || !scannedFiles.length ? '' : onFileCount(scannedFiles.length);
    // create button loading text
    let loadingText = '';
    if (initRender.current || configLoading) loadingText = 'Initializing';
    else if (uploadLoading) loadingText = 'Uploading';
    else if (fileStateLoading || hasFilePending) loadingText = 'Scanning';

    return (
        <div className="flos-uploadfield">
            <input
                ref={fileSelectorRef}
                data-testid="file-input"
                id="fileUpload"
                style={{ display: 'none' }}
                accept={acceptedExtensions.toString()}
                type="file"
                multiple
                onChange={onFileChange}
                onClick={(e) => {
                    (e.target as HTMLTextAreaElement).value = '';
                }}
            />

            <label className="flos-uploadfield-label">
                <span>{label}</span>
                {fileCounter && <span>{fileCounter}</span>}
            </label>

            {errorMessages && errorMessages.length > 0 && (
                <div className="flos-uploadfield--errorlist">
                    {errorMessages.map((message, index) => {
                        if (message.length > 0) {
                            return (
                                <Message
                                    data-testid="error-message"
                                    key={`error-${index}`}
                                    dismissible
                                    title={<p className="small u-spacing-stack-none">{message}</p>}
                                    theme="error"
                                    className={cx({ 'u-spacing-stack-s': errorMessages.length > 1 })}
                                />
                            );
                        } else {
                            return <React.Fragment key={index} />;
                        }
                    })}
                </div>
            )}

            <ScanFileList onDelete={onFileDelete} deleteButtonLabel={deleteButtonLabel} fileList={scannedFiles} loading={uploadLoading} />

            <Button
                theme="secondary"
                iconShape="upload"
                className="flos-uploadfield-button"
                onClick={handleFileSelect}
                loadingText={loadingText}
                isLoading={hasFilePending || uploadLoading || fileStateLoading}
                disabled={hasFilePending || uploadLoading || fileStateLoading}
            >
                {uploadButtonLabel}
            </Button>

            <p className="flos-uploadfield-description">{description}</p>
        </div>
    );
};

export interface UploadFieldWithServicesProps extends Omit<UploadFieldProps, keyof IUploadServiceContext> {
    /** a callback function to intercept selected files after files is process by UploadServiceProvider and before upload  */
    selectedFilesParser?: (files: IFile[]) => IFile[];
}

export const UploadFieldWithServices: React.FC<UploadFieldWithServicesProps> = ({ selectedFilesParser, ...props }) => {
    return (
        <UploadServiceContext.Consumer>
            {({ onFileChange, ...context }) => {
                const onFileChangeWithParser = (event: React.ChangeEvent<HTMLInputElement>) => onFileChange(event, selectedFilesParser);
                return <UploadField {...(props as UploadFieldProps)} {...context} onFileChange={onFileChangeWithParser} />;
            }}
        </UploadServiceContext.Consumer>
    );
};

export default UploadFieldWithServices;
