(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dawa-autocomplete2"), require("react"), require("react-animate-height"), require("react-datepicker"), require("react-number-format"), require("react-textarea-autosize"), require("@radix-ui/react-tooltip"), require("@react-spring/web"));
	else if(typeof define === 'function' && define.amd)
		define("@flos/react-ui", ["dawa-autocomplete2", "react", "react-animate-height", "react-datepicker", "react-number-format", "react-textarea-autosize", , ], factory);
	else if(typeof exports === 'object')
		exports["@flos/react-ui"] = factory(require("dawa-autocomplete2"), require("react"), require("react-animate-height"), require("react-datepicker"), require("react-number-format"), require("react-textarea-autosize"), require("@radix-ui/react-tooltip"), require("@react-spring/web"));
	else
		root["@flos/react-ui"] = factory(root["dawa-autocomplete2"], root["react"], root["react-animate-height"], root["react-datepicker"], root["react-number-format"], root["react-textarea-autosize"], root["@radix-ui/react-tooltip"], root["@react-spring/web"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__651__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__555__, __WEBPACK_EXTERNAL_MODULE__731__, __WEBPACK_EXTERNAL_MODULE__199__, __WEBPACK_EXTERNAL_MODULE__986__, __WEBPACK_EXTERNAL_MODULE__539__, __WEBPACK_EXTERNAL_MODULE__482__) => {
return 