import cx from 'classnames';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

/**
 * This type contains copy pasted type from react-textarea-autosize
 * The followin props has been removed: useCacheForDOMMeasurements, onHeightChange, rows
 */
type TextareaProps = {
    /**
     * Current textarea value
     */
    value?: string;
    /**
     * Callback on value change
     * @param event
     */
    onChange?: ((event: React.ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
    /**
     * Alias for `rows`.
     */
    minRows?: number;
    /**
     * Maximum number of rows to show.
     */
    maxRows?: number;
    /**
     * Number of caracter the user can type in the field
     */
    maxLength?: number;
} & Omit<React.ComponentPropsWithRef<'textarea'>, 'rows'>;

/**
 * This is a barebones textarea, use if you need to compose your own components or require some custom functionality for the textarea.
 *
 * You probably want to use the TextareaField component instead.
 *
 * Textarea is a wrapper around [react-textarea-autosize](https://github.com/andreypopp/react-textarea-autosize) component,
 * thus it would accepts all props a `<textarea />` accepts.
 * Use `ref` to access the underlying `<textarea />` element.
 */
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, onChange, value, maxLength, ...props }, ref: React.RefObject<HTMLTextAreaElement>) => {
    const [internalValue, setInternalValue] = React.useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInternalValue(event.target.value);
        onChange && onChange(event);
    };

    return <TextareaAutosize className={cx('flos-input', className)} placeholder=" " onChange={handleChange} value={internalValue} ref={ref} {...(props as any)} />;
});
Textarea.displayName = 'Textarea';
export type { TextareaProps };
export default Textarea;
