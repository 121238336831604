/** Represents the type of the file name */
export type FileNameType = string;

export type ErrorType = 'FILE_TOO_LARGE' | 'FILE_TOO_SMALL' | 'FILE_IS_INFECTED' | 'FILE_INVALID_TYPE' | 'TOO_MANY_FILES' | 'SERVICE_ERROR';
export type FileScanStatusType = 'pending' | 'scanning' | 'clean' | 'infected' | 'error';

/**
 * Represents a file to be uploaded, with metadata and a scan status.
 * @property key - a string representing a unique identifier for the file.
 * @property scanStatus - a value from the SCAN_STATUS enum indicating the current scanning status of the file.
 * @property $metaData - an object containing metadata for the file, specifically the File object itself.
 */
export interface IFile {
    key: FileNameType;
    scanStatus: FileScanStatusType;
    $metaData?: { [key: string]: string | Blob };
}

/**
 * Represents an error encountered while uploading a file.
 * @property fileName - a string representing the name of the file that encountered the error.
 * @property error - a string representing the error message for the error encountered.
 */
export interface IFileError {
    fileName?: string;
    error: ErrorType;
    metaData?: { [key: string]: string | number };
}

/**
 * Represents an error encountered while communicating with the upload service.
 * @property fileName - a string representing the name of the file associated with the error.
 * @property errorMessage - a string representing the error message for the error encountered.
 * @property serviceName - a string representing the name of the service that encountered the error.
 */
export interface IServiceError {
    serviceName?: string;
    error: ErrorType;
    metaData?: { [key: string]: string | number };
}

/**
 * Represents the client configuration object for the upload service.
 * Defines the accepted file extensions, maximum upload file size, and minimum upload file size.
 * @property acceptedExtensions - an array of strings representing the file extensions that are allowed to be uploaded.
 * @property consumerBucket - a string representing the consumer bucket.
 * @property friendlyName -  a string representing the friendly name of the client.
 * @property maxUploadFileSizeInMb - a number representing the maximum size of an uploaded file in megabytes.
 * @property minUploadFileSize - a number representing the minimum size of an uploaded file in bytes.
 */
export interface IClientConfig {
    acceptedExtensions: string[];
    consumerBucket: string;
    friendlyName: string;
    maxUploadFileSizeInMb: number;
    minUploadFileSize: number;
}

/** Represent required header when using upload service API
 * @property x-top-appname - required by C9 platform API.
 * @property x-top-client-id - unique ID given by C9 platform depending on team or usecase.
 * @property x-top-task-id - unique ID generated based on session.
 * @property x-top-env - extra info to attach together with the file uploaded.
 *
 */
export interface IRequestHeader {
    'x-top-appname': string;
    'x-top-client-id': string;
    'x-top-task-id': string;
    'x-top-env'?: string;
}

/**
 * Represents the types of error that might occur during upload process.
 * @property FILE_TOO_LARGE - the file exceed the maximum size
 * @property FILE_TOO_SMALL - the file is below the minimum file size requirement
 * @property FILE_IS_INFECTED - the file found infected during scan process
 * @property FILE_INVALID_TYPE - the file type is not allowed to be upload
 * @property TOO_MANY_FILES - the total of the files is exceed the maxinum length
 * @property SERVICE_ERROR - general request failure on any request within the upload services
 */
export enum ERROR_TYPE {
    FILE_TOO_LARGE = 'FILE_TOO_LARGE',
    FILE_TOO_SMALL = 'FILE_TOO_SMALL',
    FILE_IS_INFECTED = 'FILE_IS_INFECTED',
    FILE_INVALID_TYPE = 'FILE_INVALID_TYPE',
    TOO_MANY_FILES = 'TOO_MANY_FILES',
    SERVICE_ERROR = 'SERVICE_ERROR',
}

/**
 * Represents the scan status of a file being uploaded.
 * @property PENDING - the file is waiting to be upload
 * @property SCANNING - the file still scanning in progress
 * @property CLEAN - the file has passed the scanning process
 * @property INFECTED - the file found virus infected during scan process
 * @property ERROR - there is error occur during the scanning process
 */
export enum SCAN_STATUS {
    PENDING = 'pending',
    SCANNING = 'scanning',
    CLEAN = 'clean',
    INFECTED = 'infected',
    ERROR = 'error',
}

/**
 * Represents the configuration options for the upload service provider.
 * @typeDef IConfig
 * @property maxUploadFileSizeInMb - a number representing the maximum size of an uploaded file in megabytes.
 * @property minUploadFileSize - a number representing the minimum size of an uploaded file in bytes.
 * @property apiBaseUrl - a string representing the base URL for the API endpoint.
 * @property appname - a string representing the name of the application.
 * @property clientId - a string representing the unique identifier for the client.
 * @property taskId - a string representing the unique identifier for the task.
 * @property metaData - an object containing any extra metadata associated with the file.
 * @property totalFilesLimit - a number representing the maximum number of files that can be uploaded.
 */
export interface IConfig {
    maxUploadFileSizeInMb?: number;
    minUploadFileSize?: number;
    apiBaseUrl: string; // base URL for the API endpoint (staging/production)
    appname: string; // required by C9 platform API
    clientId: string; // unique ID given by C9 platform depending on team or usecase
    taskId: string | null | undefined; // unique ID generated based on session
    metaData?: { [key: string]: string }; // extra info to attach together with the file uploaded
    totalFilesLimit?: number;
}

/**
 * Represents the context object for the upload service provider.
 * @property acceptedExtensions - an array of accepted file extensions for the upload service.
 * @property scannedFiles - an array of files that have been scanned.
 * @property selectedFiles - an array of files that have been selected for upload.
 * @property errors - an array of errors that have occurred during file upload or processing.
 * @property onFileChange - a function that handles file selection, optional callback to format the files before upload
 * @property onFileDelete - a function that handles file deletion.
 * @property fileStateLoading - a boolean indicating whether file state is currently being loaded.
 * @property configLoading - a boolean indicating whether the client configuration is currently being loaded.
 * @property uploadLoading - a boolean indicating whether files are currently being uploaded.
 */
export interface IUploadServiceContext {
    acceptedExtensions?: string[];
    scannedFiles: IFile[] | [];
    selectedFiles: IFile[] | [];
    errors: (IFileError | IServiceError)[] | [];
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>, parser?: (files: IFile[]) => IFile[]) => void;
    onFileDelete: (fileName: FileNameType) => void;
    fileStateLoading?: boolean;
    configLoading?: boolean;
    uploadLoading?: boolean;
}

/**
 * Rpresents interface of UploadField Props
 * @param {string} props.deleteButtonLabel - label text to be shown in delete button
 * @param {string} props.uploadButtonLabel - label text to be shown in upload button
 * @param {string} props.label - label text to be shown on top left of upload field
 * @param {string} props.description - descriptive text that displayed at the bottom of the upload button label
 * @param {IFileError[]} props.errorMessages - a array of error messages to be shown within the upload component
 * @param {function} props.onError - a callback callback when encounter an error
 * @param {function} props.onChange - a callback when scanned file list changed
 * @param {function} props.onInit - a callback after first get files service is called
 * @param {function} props.onFileCount - a callback to return file count label text
 * @param {[IFile]} props.scannedFiles - an array of files that have been scanned.
 * @param {[IFile]} props.selectedFiles - an array of files that have been selected for upload.
 * @param {[IFileError]} props.errors - an array of errors that have occurred during file upload or processing.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onFileChange - a function that handles file selection.
 * @param {(fileName: FileNameType) => Promise<void>} props.onFileDelete - a function that handles file deletion.
 * @param {boolean} props.fileStateLoading - a boolean that will toggle a loading indicator for file is in scanning state.
 * @param {boolean} props.configLoading - a boolean that will toggle a loading indicator for initiating component.
 * @param {boolean} props.uploadLoading - a boolean that will toggle a loading indicator for uploading file.
 */
export interface UploadFieldProps {
    /** label text to be shown in delete button */
    deleteButtonLabel: string;
    /** label text to be shown in upload button */
    uploadButtonLabel: string;
    /** a callback callback when encounter an error */
    onError: (errors: IFileError[]) => void;
    /** a callback when scanned file list changed */
    onScan: (files: IFile[]) => void;
    /** an array of files that have been scanned. */
    scannedFiles: IFile[] | [];
    /** an array of files that have been selected for upload. */
    selectedFiles: IFile[] | [];
    /** an array of errors that have occurred during file upload or processing. */
    errors: (IFileError | IServiceError)[] | [];
    /** a function that handles file selection. */
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /** a function that handles file deletion. */
    onFileDelete: (fileName: FileNameType) => void;
    /** label text to be shown on top left of upload field */
    label?: string;
    /** descriptive text that displayed at the bottom of the upload button label */
    description?: string;
    /** a array of error messages to be shown within the upload component */
    errorMessages?: string[];
    /** a callback after first get files service is called */
    onInit?: () => void;
    /** a callback to return file count label text */
    onFileCount?: (count: number) => string;
    /** an array of accepted file extensions for the upload service. */
    acceptedExtensions?: string[];
    /** a boolean that will toggle a loading indicator for file is in scanning state */
    fileStateLoading?: boolean;
    /** a boolean that will toggle a loading indicator for initiating component. */
    configLoading?: boolean;
    /** a boolean that will toggle a loading indicator for uploading file. */
    uploadLoading?: boolean;
}

/**
 * The default accepted file extensions for the upload service.
 */
export const DEFAULT_ACCEPTED_ATTRS = ['.jpg', '.jpeg', '.gif', '.bmp', '.tif', '.tiff', '.png', '.doc', '.docx', '.pdf', '.rtf', '.txt', '.xls', '.xlsx'];

/** Represent value mapping of css classes base on the status provided */
export const CSS_STATUS_MAP = {
    [SCAN_STATUS.CLEAN]: 'is-clean',
    [SCAN_STATUS.INFECTED]: 'has-error',
    [SCAN_STATUS.ERROR]: 'has-error',
    [SCAN_STATUS.PENDING]: 'is-loading',
    [SCAN_STATUS.SCANNING]: 'is-loading',
};
