import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { TooltipProps as TooltipPrimitiveProps } from '@radix-ui/react-tooltip';

interface TooltipProps extends TooltipPrimitiveProps {
    content: React.ReactNode;
    showArrow?: boolean;
    id?: string;
}

/**
 * A tooltip that displays information related to an element when the mouse hovers over it.
 */
export const Tooltip = ({ children, content = '', open, showArrow = false, ...props }: TooltipProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        setIsOpen(!!open);
    }, [open]);

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={125} open={isOpen} onOpenChange={setIsOpen}>
                <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
                <TooltipPrimitive.Content align="start" alignOffset={100} side="bottom" sideOffset={-20} className="flos-tooltip" style={{ zIndex: 100 }} {...props}>
                    {content}
                    {showArrow ? <TooltipPrimitive.Arrow /> : null}
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};

export type { TooltipProps };
export default Tooltip;
