import React from 'react';
import { ModalBody } from './modal-body';
import { ModalDialog } from './modal-dialog';
import { ModalFooter } from './modal-footer';
import { ModalHeader } from './modal-header';
import { ModalOverlay } from './modal-overlay';

export type ModalProps = {
    /**
     * state if the modal is opened (shown)
     */
    isOpen: boolean;
    /**
     * callback to invoke when user attempt to close it
     * (by clicking on the overlay or press Esc key)
     */
    onDismiss: () => void;
    children: React.ReactNode;
    id?: string;
    /**
     * sets the modal footer content
     */
    footer?: React.ReactNode;
    /**
     * sets the modal header title
     */
    title?: React.ReactNode;
    /**
     * If you want additional content in the modal header section
     */
    header?: React.ReactNode;
    /**
     * Sets the position of the modal.
     * Defaults to top
     */
    position?: undefined | 'center';
    /**
     * Specifies the background color of the modal.
     * Default is white.
     */
    background?: undefined | 'transparent';
    /**
     * Specifies if the mddal dialog should be in full screen.
     * Default is false.
     */
    isFullScreen?: boolean;
    /**
     * onClick handler if you want a close icon in upper right corner
     */
    closeIconHandler?: () => void;
};
/**
 * `Modal` is a composition of `ModalOverlay`, `ModalDialog`, `ModalHeader`,
 * `ModalBody`, and `ModalFooter`. If you need more customization, you could
 * compose them yourself by using the source code of this component as reference.
 */
export const Modal: React.FC<ModalProps> = ({ isOpen, onDismiss, title, header, footer, id, position, background, isFullScreen, closeIconHandler, children }) => {
    return (
        <ModalOverlay isOpen={isOpen} onDismiss={onDismiss} id={id} isFullScreen={isFullScreen}>
            <ModalDialog isFullScreen={isFullScreen} closeIconHandler={closeIconHandler} position={position} background={background}>
                <ModalHeader title={title}>{header}</ModalHeader>
                <ModalBody>{children}</ModalBody>
                <ModalFooter>{footer}</ModalFooter>
            </ModalDialog>
        </ModalOverlay>
    );
};
