import React from 'react';
import cx from 'classnames';
import { FlosInput, FlosInputProps } from '../input/flos-input';

export type CheckboxProps = {
    value?: string | number;
    checked?: boolean;
    label: React.ReactNode;
    text?: React.ReactNode;
} & Omit<FlosInputProps, 'label'>;
/**
 * Input is a wrapper around `<input />` html element, thus it would accepts all props a `<input />` accepts.
 *
 * You can use `ref` to access the underlying `<input />` element
 *
 * Usually this component is used in composition with other Components
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ ...props }, ref: React.RefObject<HTMLInputElement>) => {
    let checkboxRef: React.RefObject<HTMLInputElement> = ref;
    const fallbackRef = React.useRef<HTMLInputElement>(null);
    if (!ref) checkboxRef = fallbackRef;

    const isChecked = checkboxRef?.current?.checked;

    return (
        <>
            <FlosInput {...props} id={props.id} disabled={props.disabled} placeholder=" " type="checkbox" ref={checkboxRef} />
            <label htmlFor={props.id} className={cx('flos-checkbox', { 'is-disabled': props.disabled, 'flos-checkbox--checked': isChecked })}>
                <div className="flos-checkbox--label">
                    <flos-icon class="dot" shape="check2" aria-hidden="true" size={24} />
                    {props.label}
                    {props.required && '*'}
                </div>
                {props.text && <div className="flos-checkbox--text">{props.text}</div>}
            </label>
        </>
    );
});

Checkbox.displayName = 'Checkbox';
