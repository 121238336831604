import React, { Suspense, useState, useEffect, useContext } from 'react';
import logFactory, { C9Logger } from 'c9-js-log-client';
import { ModalOverlay, ModalDialog, LoadingDots } from '@flos/react-ui';
import { ConfigContext } from './context/ConfigContext';
import { appConfig } from './config';
import LoggerContext from './context/LoggerContext';

const App = React.lazy(() => import('./App')); // Lazy-loaded

let logger: C9Logger;
if (appConfig.endpointConfig.logger === 'console') {
    logger = logFactory(`${appConfig.endpointConfig.logger}`, appConfig.appName, appConfig.logLevel);
} else {
    logger = logFactory(`${appConfig.endpointConfig.logger}/log`, appConfig.appName, appConfig.logLevel);
}
logger.debug(`Starting up application ${logger.getApplicationName()}`);

const Bootstrap = (): JSX.Element => {
    const configContext = useContext(ConfigContext);
    const [showModal, setShowModal] = useState<boolean>(true);
    const [isLoaded, setIsLoaded] = useState(false);

    /**
     * Called when closing or dismissing the overlay
     */
    const onCloseHandler = () => {
        setShowModal(false);
        window.history.pushState(null, '', window.location.pathname);
    };

    useEffect(() => {
        // subscribe to banner event
        if (!isLoaded) {
            setIsLoaded(true);
            configContext.hybridAppEventBus.subscribe('ONSA-OVERLAY-START', () => {
                setShowModal(true);
            });
        }
    }, [isLoaded, configContext.hybridAppEventBus]);

    useEffect(() => {
        if (showModal) {
            window.history.pushState(null, '', '#bestil');
        }
    }, [showModal]);

    return (
        <LoggerContext.Provider value={logger}>
            <ModalOverlay isFullScreen id="onsa-overlay" isOpen={showModal} onDismiss={onCloseHandler}>
                <ModalDialog closeIconHandler={onCloseHandler} isFullScreen>
                    <Suspense fallback={<LoadingDots />}>
                        <App onCloseHandler={onCloseHandler} />
                    </Suspense>
                </ModalDialog>
            </ModalOverlay>
        </LoggerContext.Provider>
    );
};

export default Bootstrap;
