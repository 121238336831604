import React from 'react';
import { IFile, IFileError, DEFAULT_ACCEPTED_ATTRS, SCAN_STATUS, IServiceError, FileNameType, IRequestHeader, IConfig, IUploadServiceContext } from './types';
import { useUploadClientConfig, useFileState, useUploadFile } from './use-upload-services';
import { getInvalidTypeRejectionErr, getServiceRejectionErr, getTooLargeRejectionErr, getTooManyFilesRejectionErr, getTooSmallRejectionErr, getUniqueFileName } from './helpers';
import accept from 'attr-accept';
import { deleteFile } from './upload-service';

/**
 * Represents the props for the UploadServiceProvider component.
 * @param {IConfig} config - a configuration object for the upload service provider.
 * @param {JSX.Element} children - a JSX element representing the child components of the upload service provider.
 */
export type UploadServiceProviderProps = {
    /** a configuration object for the upload service provider. */
    config: IConfig;
    /** a JSX element representing the child components of the upload service provider. */
    children: JSX.Element;
};

/** Represent the initial state for the upload service provider */
const initialState: IUploadServiceContext = {
    acceptedExtensions: DEFAULT_ACCEPTED_ATTRS,
    scannedFiles: [],
    selectedFiles: [],
    errors: [],
    onFileChange: () => Promise.resolve(),
    onFileDelete: () => Promise.resolve(),
    fileStateLoading: false,
    configLoading: false,
    uploadLoading: false,
};

/** Represents the context object for the upload service provider */
export const UploadServiceContext = React.createContext<IUploadServiceContext>(initialState);

/**
 * A React component that provides the file upload service.
 * @param props.config - an object containing configuration parameters for the file upload service.
 * @param props.config.maxUploadFileSizeInMb - the maximum file size (in MB) allowed for upload.
 * @param props.config.minUploadFileSize - the minimum file size (in bytes) allowed for upload.
 * @param props.config.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param props.config.appname - required by C9 platform API.
 * @param props.config.clientId - unique ID given by C9 platform depending on team or usecase.
 * @param props.config.taskId - unique ID generated based on session.
 * @param props.config.metaData - extra info to attach together with the file uploaded.
 * @param props.config.totalFilesLimit - the maximum number of files that can be uploaded.
 * @param children - the child components that will be rendered within this component.
 * @returns a React component that provides the file upload service.
 */
export const UploadServiceProvider = ({ config, children }: UploadServiceProviderProps) => {
    // Set default values for the maximum and minimum file size limits, and the maximum file count.
    const { maxUploadFileSizeInMb = 20, minUploadFileSize = 32, apiBaseUrl, taskId, clientId, appname, metaData, totalFilesLimit = 50 } = config;
    // Set up state variables for selected files and errors.
    const [selectedFiles, setSelectedFiles] = React.useState<IFile[]>([]);
    const [errors, setErrors] = React.useState<(IFileError | IServiceError)[]>([]);
    // Determine the maximum number of files that can be uploaded, based on the configuration.
    const MAX_FILES_COUNT = totalFilesLimit ? (totalFilesLimit > 50 ? 50 : totalFilesLimit) : 10;

    // Set up a function for setting file errors.
    const setFileError = (error: IFileError) => setErrors((prevErrors) => [...prevErrors, error]);
    // Set up the request headers for API calls.
    const requestHeaders = React.useMemo(() => {
        const headers: IRequestHeader = {
            'x-top-appname': appname,
            'x-top-client-id': clientId,
            'x-top-task-id': taskId || '',
        };
        if (metaData?.environment) headers['x-top-env'] = metaData.environment;
        return headers;
    }, [appname, clientId, taskId, metaData]);

    // Fetch the client configuration from the server.
    const [configError, configLoading, clientConfig] = useUploadClientConfig({
        maxUploadFileSizeInMb,
        minUploadFileSize,
        apiBaseUrl,
        requestHeaders,
    });
    // Upload any new files that have been selected.
    const [uploadError, uploadLoading, uploadedFileList] = useUploadFile({
        apiBaseUrl,
        requestHeaders,
        files: selectedFiles,
        metaData,
    });
    // Fetch the state of any files already uploaded.
    const [filesError, fileStateLoading, scannedFiles, setScanFileList] = useFileState({
        apiBaseUrl,
        requestHeaders,
        files: uploadedFileList,
    });

    // Combine all errors into a single array.
    const allErrors = React.useMemo(() => {
        const errs = [...errors, ...filesError, ...uploadError, ...configError];
        return errs;
    }, [errors, filesError, uploadError, configError]);

    // init the selected files list after files uploaded
    React.useEffect(() => {
        setSelectedFiles([]);
    }, [uploadedFileList]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>, parser?: (file: IFile[]) => IFile[]) => {
        event.preventDefault();
        setSelectedFiles(() => {
            const newSelectedFiles: IFile[] = [];
            const currentFilesCount = scannedFiles.length;
            Array.prototype.slice.call(event.target.files).forEach((newFile: File, index: number) => {
                const newFileSizeInMb = newFile.size / 1000000;
                const newFileName = getUniqueFileName(newFile.name, scannedFiles);

                const isAcceptedAttr = accept(
                    {
                        name: newFileName,
                        type: newFile.type,
                    },
                    clientConfig.acceptedExtensions.toString()
                );
                if (currentFilesCount + (index + 1) > MAX_FILES_COUNT) {
                    setFileError(getTooManyFilesRejectionErr(newFileName, MAX_FILES_COUNT));
                } else if (!isAcceptedAttr) {
                    setFileError(getInvalidTypeRejectionErr(newFileName));
                } else if (newFileSizeInMb > clientConfig.maxUploadFileSizeInMb) {
                    setFileError(getTooLargeRejectionErr(newFileName, clientConfig.maxUploadFileSizeInMb));
                } else if (newFile.size < clientConfig.minUploadFileSize) {
                    setFileError(getTooSmallRejectionErr(newFileName, clientConfig.minUploadFileSize));
                } else {
                    newSelectedFiles.push({
                        key: newFileName,
                        scanStatus: SCAN_STATUS.PENDING,
                        $metaData: { file: newFile },
                    });
                }
            });
            if (!parser) return newSelectedFiles;
            return parser(newSelectedFiles).map((file) => ({
                ...file,
                key: getUniqueFileName(file.key, [...scannedFiles, ...newSelectedFiles]),
            }));
        });
    };

    const onFileDelete = async (fileName: FileNameType) => {
        // delete from selected file list
        const fileIndex = scannedFiles.findIndex((file) => file.key === fileName);
        // When files are deleted, remove them from the selected files list.

        // If file had been uploaded to S3 bucket, call delete file service,
        if (fileIndex >= 0) {
            const fileToDelete = { ...scannedFiles[fileIndex] };
            setScanFileList((currScannedFiles) => {
                // Delete the file directly without waiting
                return currScannedFiles.filter((f) => f.key !== fileName);
            });
            return deleteFile({
                url: `${apiBaseUrl}/upload/v1/remove?fileName=${fileName}`,
                headers: { ...requestHeaders },
            }).catch((errorMessage: Error) => {
                setFileError(getServiceRejectionErr(fileName, errorMessage.message, 'deleteFile'));
                setScanFileList((currScannedFiles) => {
                    // Restore the file if deletion failed
                    return [...currScannedFiles, fileToDelete];
                });
            });
        }
        return Promise.resolve();
    };

    // Render the child components, passing down the file upload service context.
    return (
        <UploadServiceContext.Provider
            value={{
                acceptedExtensions: clientConfig.acceptedExtensions,
                errors: allErrors,
                onFileChange,
                onFileDelete,
                scannedFiles,
                selectedFiles,
                configLoading,
                fileStateLoading,
                uploadLoading,
            }}
        >
            {children}
        </UploadServiceContext.Provider>
    );
};

/**
 * A higher-order component that wraps a component with the UploadServiceContext consumer.
 * @param WrappedComponent - the component to be wrapped with the UploadServiceContext consumer.
 * @returns a new component that wraps the input component with the UploadServiceContext consumer.
 */
export function withUploadService<T extends IUploadServiceContext>(WrappedComponent: React.ComponentType<T>) {
    return function ComponentWithUploadService(props: Omit<T, keyof IUploadServiceContext>) {
        return <UploadServiceContext.Consumer>{(context) => <WrappedComponent {...(props as T)} {...context} />}</UploadServiceContext.Consumer>;
    };
}
