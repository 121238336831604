import React from 'react';
import FlosIcon from '../../core/icon/flos-icon';
import cx from 'classnames';
import { AccordionPanelProps } from './accordion-panel';
import { ReactElement } from 'react';

type AccordionItemProps = {
    index: number;
    isExpandedInternal: boolean;
    onToggleInternal: (index: number) => void;
    children: ReactElement<AccordionPanelProps>;
} & AccordionPanelProps &
    React.ComponentPropsWithoutRef<'button'>;

const AccordionItem: React.FC<AccordionItemProps> = ({ children, title, subtitle, infoArea, onChange, onToggleInternal, index, isOpen, isExpandedInternal, id }) => {
    const titleRef = React.useRef<HTMLButtonElement | null>(null);

    const onClickHandler = React.useCallback(() => {
        if (!isExpandedInternal) {
            titleRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
        onToggleInternal(index);
    }, [onToggleInternal, index, isExpandedInternal]);

    React.useEffect(() => {
        if (!!isOpen && !isExpandedInternal) {
            onClickHandler();
        }
    }, [isOpen, isExpandedInternal, onClickHandler]);

    React.useEffect(() => {
        onChange && onChange(isExpandedInternal);
    }, [isExpandedInternal, onChange]);

    return (
        <div className={cx('flos-accordion-panel', isExpandedInternal && 'expanded')} id={id}>
            <button
                className={'flos-accordion-panel-button'}
                aria-controls={`${id}-content-wrapper`}
                id={`${id}-button`}
                aria-selected={isExpandedInternal}
                role="tab"
                onClick={() => onClickHandler()}
                ref={titleRef}
            >
                <div className={'flos-accordion-panel-content'}>
                    <div className={'flos-accordion-panel-title'}>
                        <h6 className={'u-spacing-stack-none'}>{title}</h6>
                        {subtitle && <p className={'u-spacing-stack-none'}>{subtitle}</p>}
                    </div>
                    {infoArea && <div className={'flos-accordion-panel-infoarea'}>{infoArea}</div>}
                </div>
                <FlosIcon className="flos-accordion-panel-toggle-icon" shape="arrow-down" size={16} />
            </button>
            <div className={cx('flos-accordion-content-wrapper')} id={`${id}-content-wrapper`} role="tabpanel" aria-expanded={isExpandedInternal} aria-labelledby={`${id}-button`}>
                {children}
            </div>
        </div>
    );
};

export type { AccordionItemProps };
export { AccordionItem };
export default AccordionItem;
