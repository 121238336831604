import React from 'react';
import { Dropdown, DropdownProps } from '../navigation/dropdown/dropdown';
import { DropdownItem } from '../navigation/dropdown/dropdown-item';
import { useState } from 'react';
import type { StakeholderClientProps } from '../../types/types';

type StakeholderSelectorProps = {
    stakeholderFilter?: 'GROUPED' | 'EXPANDED' | 'BROKER' | 'ACTIVEUSER' | 'ACTIVEUSER_AND_CVR';
    /**
     * Name of the consuming app - needed for the topContext.stakeholder
     **/
    appName: string;
    /**
     * Callback when selected viewId changes
     **/
    onChangeHandler?: (viewId: string) => void;
    /**
     * Callback if error occurs on getViews or setSelectedView on stakeholder
     **/
    onErrorHandler?: (errorMessage: string) => void;
} & Omit<DropdownProps, 'value' | 'onChange' | 'children' | 'size' | 'label'>;

type StakeholderViewProps = {
    id: string;
    name: string;
    selected: boolean;
    filter: string;
    type: string;
    activeUser: boolean;
    source: string;
    totalHouseholdMember: number;
};

export const StakeholderSelector = React.forwardRef<HTMLButtonElement, StakeholderSelectorProps>(
    ({ stakeholderFilter = 'GROUPED', appName, onChangeHandler, onErrorHandler, ...props }: StakeholderSelectorProps, ref) => {
        const stakeholderClient: StakeholderClientProps = window.topContext.stakeholder;
        const [views, setViews] = useState<StakeholderViewProps[]>([]);
        const [selectedLabel, setSelectedLabel] = useState<string>('');
        const [selectedView, setSelectedView] = useState<StakeholderViewProps | null>(null);

        React.useEffect(() => {
            if (!selectedView) {
                return;
            }
            const setViewInClient = async () => {
                try {
                    await stakeholderClient.setSelectedView(selectedView.id, appName);
                } catch (e) {
                    const message = `Error from StakeholderSelector when setting selected view`;
                    onErrorHandler && onErrorHandler(message);
                }
            };
            setViewInClient();
        }, [selectedView, stakeholderClient, appName, onErrorHandler]);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value) {
                const newSelectedView = getViewById(e.target.value);
                if (newSelectedView) {
                    setSelectedView(newSelectedView);
                    setSelectedLabel(newSelectedView.name);
                    onChangeHandler && onChangeHandler(newSelectedView.id);
                }
            }
        };

        const getViewById = (viewId: string) => {
            return views.find((view: StakeholderViewProps) => view.id === viewId);
        };

        const getSelectedView = (views: StakeholderViewProps[]) => {
            // find the selected view
            const selectedView = views.find((view: StakeholderViewProps) => view.selected);
            // If none - then select the first and set it  - else just set the selected (to make sure event is published by stakeholder client on render)
            if (!selectedView) {
                // if no view is selected - then default to first one and update
                views[0].selected = true;
                return views[0];
            }
            return selectedView;
        };

        const getStakeholderViews = React.useCallback(async () => {
            try {
                const stakeholderViews: { views: StakeholderViewProps[] } = await stakeholderClient.getViews(stakeholderFilter, false, appName);
                setSelectedView(getSelectedView(stakeholderViews.views));
                return stakeholderViews.views;
            } catch (e) {
                const message = `Error from StakeholderSelector when getting views`;
                onErrorHandler && onErrorHandler(message);
                return [];
            }
        }, [appName, onErrorHandler, stakeholderClient, stakeholderFilter]);

        React.useEffect(() => {
            getStakeholderViews().then((viewsFromClient: StakeholderViewProps[]) => {
                if (viewsFromClient.length <= 1 && onChangeHandler) onChangeHandler(viewsFromClient[0].id);
                setViews(viewsFromClient);
            });
        }, [getStakeholderViews, onChangeHandler]);

        if (views.length === 0) return null;

        if (views.length > 1) {
            return (
                <div className={'u-flex-box u-flex-box--gap-small u-flex-box--center'}>
                    <span>{selectedLabel}</span>
                    <Dropdown {...props} onChange={handleChange} size={'small'} ref={ref}>
                        {views.map((view, index) => {
                            return (
                                <DropdownItem key={'key-' + index} value={view.id} selected={view.selected}>
                                    {view.name}
                                </DropdownItem>
                            );
                        })}
                    </Dropdown>
                </div>
            );
        }

        if (views[0].id === 'HOUSE_HOLD_GROUPED' && views[0].totalHouseholdMember > 1) {
            return <span>{views[0].name}</span>;
        }

        return null;
    }
);

StakeholderSelector.displayName = 'StakeholderSelector';

export type { StakeholderSelectorProps };
export default StakeholderSelector;
