import React from 'react';
import cx from 'classnames';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { Pill } from '../../attention-elements/pill/pill';
import { useId } from '../../../utils/hooks';
import { noop } from '../../../utils';

type CommonProps = {
    title: string;
    label?: string;
    selectable?: boolean;
    selected?: boolean;
    link?: string;
    variant?: 'default' | 'compact' | 'light' | 'title-outside' | 'large';
} & Omit<React.HTMLAttributes<HTMLAnchorElement>, 'onSelect'>;

type ConditionalProps =
    | {
          /** Image URL */
          imageUrl: string;
          iconShape?: never | undefined;
      }
    | {
          imageUrl?: never | undefined;
          /** Icon attribute */
          iconShape: string;
      };

type IconBoxProps = CommonProps & ConditionalProps;

type InternalIconBoxProps = {
    onSelect?: (selected: { title: string; id: string; selected: boolean | undefined; iconShape: string | undefined; imageUrl: string | undefined }) => void;
};

type IconBoxHeaderProps = Omit<IconBoxProps, 'iconShape' | 'link' | 'title'>;

const IconBoxHeader = ({ label, selectable, selected }: IconBoxHeaderProps) => {
    return (
        <div className="icon-box-header">
            {selectable && (
                <FlosIcon
                    className={selected ? 'icon-box-selected-icon has-fill is-logo-blue' : 'icon-box-selected-icon'}
                    size="medium"
                    shape={selected ? 'circle-minus' : 'circle-plus'}
                />
            )}
            {label && <Pill content={label} theme="secondary" id="test-pill-id" />}
        </div>
    );
};

const IconBox = ({
    id,
    title,
    iconShape,
    imageUrl,
    label,
    selectable = false,
    selected = false,
    link,
    onSelect = noop,
    className,
    variant = 'default',
    ...props
}: IconBoxProps & InternalIconBoxProps) => {
    const internalId = useId(id);

    const [isSelected, setIsSelected] = React.useState<boolean | undefined>(undefined);

    const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link) event.preventDefault();
        setIsSelected((prevIsSelected) => !prevIsSelected);
        onSelect({ title: title, id: internalId, selected: !isSelected, iconShape: iconShape, imageUrl: imageUrl });
    };

    React.useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div className={cx('icon-box-wrapper', variant && 'icon-box-wrapper--' + variant)}>
            <a
                id={internalId}
                className={cx('icon-box', isSelected && 'icon-box--selected', variant && 'icon-box--' + variant, className)}
                href={link}
                onClick={onClickHandler}
                style={{ flexWrap: props.children && isSelected ? 'wrap' : 'nowrap' }}
                {...props}
            >
                {variant != 'compact' && variant != 'title-outside' && variant != 'light' && <IconBoxHeader label={label} selectable={selectable} selected={isSelected} />}
                {iconShape && <FlosIcon className="icon-box-icon" shape={iconShape} size={32} />}
                {imageUrl && (
                    <div className={cx('icon-box-image')}>
                        <img alt="" src={imageUrl} />
                    </div>
                )}
                {title && (variant === 'default' || variant === 'light' || variant === 'large') && !label && (
                    <p className="h6 icon-box-title" role={'heading'}>
                        {title}
                    </p>
                )}
                {title && (variant === 'default' || variant === 'light' || variant === 'large') && label && (
                    <div className="icon-box-title-wrap">
                        <p className="h6 icon-box-title" role={'heading'}>
                            {title}
                        </p>
                        {<Pill content={label} theme="secondary" id="test-pill-id" />}
                    </div>
                )}
                {title && variant === 'compact' && <p className="icon-box-title">{title}</p>}
                {props.children && isSelected && (
                    <div className="icon-box-content" onClick={(event) => event.stopPropagation()}>
                        {props.children}
                    </div>
                )}
            </a>
            {title && variant === 'title-outside' && <p className="icon-box-wrapper-title">{title}</p>}
        </div>
    );
};

export type { IconBoxProps };
export { IconBox };
export default IconBox;
