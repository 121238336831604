import React from 'react';
import { NumericFormat, NumberFormatValues, SourceInfo } from 'react-number-format';
import { FlosInput, FlosInputProps } from '../input/flos-input';
import { useState, useRef } from 'react';

export type NumberInputProps = {
    /**
     * specify how many decimals are allowed - will behave as float - with formatting
     */
    decimalsAllowed?: number;

    /**
     * specify if decimals should be fixed. If set to true 0's will be added to match the number of decimals allowed
     */
    decimalsFixed?: boolean;

    /**
     * specify any appending suffix
     */
    suffix?: string;
    /**
     * specify if the display value should have thousand separator
     */
    withFormatting?: boolean;
    /**
     * specify if leading zeros must be allowed
     */
    allowLeadingZeros?: boolean;
    type?: 'text' | 'tel' | 'password';
    defaultValue?: number | string | null;
} & Omit<FlosInputProps, 'type'>;

/**
 * NumberInput is a wrapper of Input component that only restrict value to number.
 *
 * It currently supports integer and float number (number with decimal places).
 */
export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(function NumberInputComp(
    { value, onChange, onBlur, decimalsAllowed = 0, decimalsFixed = false, suffix, withFormatting, allowLeadingZeros = false, type = 'tel', max, min, ...inputProps },
    ref
) {
    const [formattedValue, setFormattedValue] = useState<string>(value || '');
    const inputRef = (ref as React.MutableRefObject<HTMLInputElement>) || (React.useRef() as React.MutableRefObject<HTMLInputElement>);
    const currentVal = useRef('');

    const isAllowed = (values: NumberFormatValues) => {
        if (max == null && min == null) return true;
        const { floatValue } = values;
        const v = floatValue as number;
        if (max !== null && v >= (max as number)) {
            return false;
        }
        if (min !== null && v <= (min as number)) {
            return false;
        }
        return true;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = currentVal.current;
        onChange && onChange(event);
    };
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.value = currentVal.current;
        onBlur && onBlur(event);
    };

    const handleValueChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (sourceInfo.source !== 'event') return;
        const { formattedValue, value: v } = values;
        currentVal.current = v;
        setFormattedValue(formattedValue);
    };

    return (
        <NumericFormat
            {...inputProps}
            suffix={suffix || ''}
            value={formattedValue}
            type={type}
            decimalSeparator={','}
            getInputRef={inputRef}
            thousandSeparator={withFormatting ? '.' : false}
            decimalScale={decimalsAllowed && decimalsAllowed > 0 ? decimalsAllowed : 0}
            fixedDecimalScale={decimalsFixed}
            allowLeadingZeros={allowLeadingZeros}
            customInput={FlosInput}
            isAllowed={isAllowed}
            onValueChange={handleValueChange}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
});
