import React from 'react';

type Coverage = {
    /**
     * Coverage unique identifier
     */
    id: string;
    /**
     * Coverage label
     */
    name: string;
    /**
     * Coverage label variant
     */
    variant?: 'label' | 'covered' | 'not-covered';
    /**
     * Coverage label more info
     */
    info?: React.ReactNode;
    /**
     * Coverage label icon shape
     */
    shape?: string;
};

type CoverageLabelProps = {} & Omit<Coverage, 'id'>;

const CoverageLabel = ({ name, variant = 'label', info, shape, ...props }: CoverageLabelProps) => {
    return (
        <li className="flos-package-label" {...props}>
            {variant === 'label' ? (
                <>
                    <h6>{name}</h6>
                    <p className="small">{info}</p>
                </>
            ) : variant === 'covered' ? (
                <>
                    <div className="flos-package-label-mobile">
                        <h6 className="u-spacing-stack-xs">{name}</h6>
                        <p className="small">{info}</p>
                    </div>
                    <flos-icon class={`${shape ? 'is-indigo' : 'is-green has-fill'}`} shape={shape ? shape : 'circle-check-animated'} size={32} />
                </>
            ) : (
                <>
                    <div className="flos-package-label-mobile">
                        <h6 className="u-spacing-stack-xs">{name}</h6>
                        <p className="small">{info}</p>
                    </div>
                    <flos-icon class={'has-fill is-supplement-grey-medium '} shape={'circle-close'} size={32} />
                </>
            )}
        </li>
    );
};

export type { Coverage };
export { CoverageLabel };
export default CoverageLabel;
