import React from 'react';
import TabTitle from './tab-title';
import { TabProps } from './tab';
import { cloneElement } from 'react';
import { getId } from '../../../utils';
import { IconBoxList } from '../../boxes/icon-box/icon-box-list';
import IconBox from '../../boxes/icon-box/icon-box';

type TabsProps = {
    children: React.ReactElement<TabProps>[];
    selectedIndex?: number;
    variant?: 'IconBox';
} & React.ComponentPropsWithoutRef<'div'>;

const Tabs = ({ children, id, selectedIndex, variant, ...props }: TabsProps): React.ReactElement => {
    const newProps = { ...props };
    delete newProps['aria-label'];
    id = id || getId();

    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    React.useEffect(() => {
        if (selectedIndex != undefined && selectedIndex != null && selectedIndex < children.length && selectedIndex >= 0) {
            setSelectedTab(selectedIndex as number);
        } else {
            setSelectedTab(0);
        }
    }, [selectedIndex]);

    return (
        <div className={'flos-tabs'} id={id} {...newProps}>
            {variant && variant === 'IconBox' ? (
                <IconBoxList variant={'row'}>
                    {children.map((item: any, index: number) => {
                        return (
                            <IconBox
                                id={`${id}-tab-${index}`}
                                selected={index === selectedTab}
                                onClick={() => {
                                    setSelectedTab(index);
                                    item.props.onClick && item.props.onClick();
                                }}
                                variant="title-outside"
                                key={index}
                                title={item.props.label}
                                iconShape={item.props.iconshape}
                                data-testid={item.props.label}
                            />
                        );
                    })}
                </IconBoxList>
            ) : (
                <ul className={`flos-tabs-list`} id={`${id}-tablist`} role="tablist" aria-label={props['aria-label']}>
                    {children.map((item: any, index: number) => {
                        return (
                            <TabTitle
                                isSelected={index === selectedTab}
                                label={item.props.label}
                                index={index}
                                setSelectedTab={setSelectedTab}
                                {...item.props}
                                id={`${id}-tab-${index}`}
                                key={index.toString()}
                            />
                        );
                    })}
                </ul>
            )}
            {cloneElement(children[selectedTab] as any, { id: `${id}-tab-${selectedTab}` })}
        </div>
    );
};

export type { TabsProps };
export { Tabs };
export default Tabs;
