import React from 'react';
import { FlosInput, FlosInputProps } from '../input/flos-input';
import { PatternFormat } from 'react-number-format';
import { validateCpr, validateCprAge } from '../../../utils/validate';
import { useEffect, useState } from 'react';
import { noop } from '../../../utils';

export type CprInputProps = {
    /** Validates if cpr age is above */
    minAge?: number;
    /** Validates if cpr age is below */
    maxAge?: number;
    isValidHandler?: (isValid?: boolean) => void;
    isValid?: boolean;
} & FlosInputProps;

/**
 * CprInput is a wrapper around Input component with formatting and validity check
 */
export const CprInput = React.forwardRef<HTMLInputElement, CprInputProps>(function CprInputComp(
    { value, isValid, required, onChange, onBlur, minAge, maxAge, isValidHandler = noop, ...inputProps },
    ref
) {
    const [internalValue, setInternalValue] = useState('');

    const sanitizeValue = (value: string) => value && value.replace(/\s|-/g, '');

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '' && (isValid || typeof isValid === 'undefined')) {
            isValidHandler(undefined);
        } else if (event.target.value) {
            const cprFormatIsValid = validateCpr(sanitizeValue(event.target.value));
            const ageOutOfRangeError = validateCprAge(event.target.value, {
                maxAge: maxAge,
                minAge: minAge,
            });
            setInternalValue(sanitizeValue(event.target.value));
            isValidHandler(cprFormatIsValid && ageOutOfRangeError === null);
        } else {
            isValidHandler(false);
        }
        onBlur && onBlur(event);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = sanitizeValue(event.target.value);
        setInternalValue(newValue);
        event.target.value = newValue;
        onChange && onChange(event);
    };

    useEffect(() => {
        if (value) {
            setInternalValue(sanitizeValue(value as string));
        }
    }, [value]);

    return (
        <PatternFormat
            {...(inputProps as any)}
            getInputRef={ref}
            value={sanitizeValue(internalValue)}
            format="###### - ####"
            customInput={FlosInput}
            onChange={handleChange}
            onBlur={handleBlur}
            valueIsNumericString
        />
    );
});
