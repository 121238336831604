import { useMemo } from 'react';

/**
 * Gets a nested value from an object given the dot-delimited accessor string.
 *
 * @param {Object} obj - The object to get the value from.
 * @param {string} accessor - The dot-delimited accessor string.
 * @returns {any} The value from the object.
 * @example
 * getNestedValue({a: {b: 1}}, 'a.b') // returns 1.
 * getNestedValue({a: {b: {c: {d: 2}}}}, 'a.b.c.d') // returns 2.
 */
function getNestedValue(obj: any, accessor: string) {
    return accessor.split('.').reduce((o, i) => o[i], obj);
}

/**
 * ColumnDefinition represents the structure of a column in the table.
 * Each column should have a unique ID, an accessor to get data from
 * the data source, and a header for the column title.
 *
 * @typedef {Object} ColumnDefinition
 * @property {string} id - The unique ID of the column.
 * @property {string} accessor - The dot-delimited accessor string to fetch data from the data source.
 * @property {string} header - The header or title of the column.
 */
type ColumnDefinition = {
    id: string;
    accessor: string;
    header: string;
};
/**
 * Cell represents a cell in a row of the table.
 * Each cell should have an id, a function to get the value,
 * a function to get the props, a function to get the context,
 * and a header for the cell title.
 *
 * @typedef {Object} Cell
 * @property {string} id - The unique ID of the cell.
 * @property {function} getValue - The function to fetch the cell value.
 * @property {function} getProps - The function to fetch the cell properties.
 * @property {function} getContext - The function to fetch the column definition.
 * @property {string} header - The header that associate with the cell.
 */
type Cell = {
    id: string;
    getValue: () => any;
    getProps: () => {
        key: string;
        'data-header': string;
        children: string;
    };
    getContext: () => ColumnDefinition;
    header: string;
};

type Headers = { id: string; header: string }[];
type Rows = { id: string; cells: Cell[] }[];

type UseTableData = (
    tableData: any[],
    columns: ColumnDefinition[]
) => {
    getHeaders: () => { id: string; headers: Headers }[];
    getRows: () => { rows: Rows };
};
/**
 * useTableData is a custom hook that transforms a data source and a column definition
 * into a structure that can be used to render a table.
 *
 * @param {Object[]} tableData - The data source for the table.
 * @param {ColumnDefinition} columns - The definition of the columns for the table.
 * @returns {Object} An object containing methods to get headers and rows for the table.
 *
 * @example
 * const data = [
 *   {group: "Insects", leg: "Always have 6 legs", kids: "Laying eggs"},
 *   {group: "Fish", leg: "Has fins", kids: "Lay eggs in water"},
 * ];
 *
 * const columns = [
 *   {id: 'group', accessor: 'group', header: 'Group'},
 *   {id: 'leg', accessor: 'leg', header: 'Leg'},
 *   {id: 'kids', accessor: 'kids', header: 'Kids'},
 * ];
 *
 * const table = useTableData(data, columns);
 *
 * // Then use table.getHeaders() and table.getRows() to render your table.
 */
export const useTableData: UseTableData = (tableData: any[], columns: ColumnDefinition[]) => {
    const headers: Headers = useMemo(() => {
        return columns.map((column) => ({
            id: column.id,
            header: column.header,
        }));
    }, [columns]);

    const rows: Rows = useMemo(() => {
        return tableData.map((row: { id: string; cells: Cell[] }, rowIndex: number) => {
            const rowId = `row-${rowIndex}`;
            return {
                id: rowId,
                cells: columns.map((column) => {
                    const colId = `${rowId}-col-${column.id}`;
                    return {
                        id: colId,
                        getValue: getNestedValue.bind(this, row, column.accessor),
                        getProps: () => ({
                            key: colId,
                            'data-header': column.header,
                            children: getNestedValue(row, column.accessor),
                        }),
                        getContext: () => column,
                        header: column.header,
                    };
                }),
            };
        });
    }, [tableData, columns]);

    return {
        getHeaders: () => [{ id: 'header-group', headers }],
        getRows: () => ({ rows }),
    };
};

export type { ColumnDefinition };
