import React, { useEffect } from 'react';
import cx from 'classnames';
import Box from '../../components/boxes/box/box';
import Carousel from '../../components/navigation/carousel/carousel';
import { Button } from '../../core/button/button';
import CoverageLabel, { Coverage } from './coverage-label';
import LoadingDots from '../../components/attention-elements/loading-dots/loading-dots';
import { useSelectOnScrolled } from './useSelectOnScrolled';

type PackageSelectorProps = {
    /**
     * List of packages
     */
    packages: PackageProps[];
    /**
     * Default selected package index
     */
    defaultSelectedIndex?: number;
    /**
     * Set loadingPrice true when you are want to show loading sign in place of package price
     */
    loadingPrice?: boolean;
    /**
     * Unique Coverage list for label and info
     */
    uniqueCoverageList: Coverage[];
    /**
     * Mileage selector
     */
    action: React.ReactNode;
    /**
     * Callback when selected package changes
     * @param packageIndex index of selected package
     * @returns void
     */
    onChangeHandler?: (packageIndex: string) => void;
    /**
     * Callback when click on `Vaelg` button
     * @param packageIndex index of selected package
     * @returns void
     */
    onSubmitHandler?: (packageIndex: string) => void;
};
type PackageProps = {
    /**
     * Package name
     */
    name: string;
    /**
     * Package Price with currency
     */
    price: string;
    /**
     * helpText will provide help to choose package
     */
    helpBlock?: React.ReactNode;
    /**
     * List of covered Coverage id list
     */
    coveredCoverageIds: string[];
};

const CoverageDetails = ({ coverages, coverageDetailsList }: any) => {
    return (
        <ul className="List List--icons">
            {coverageDetailsList.map((coverageDetails: Coverage, index: number) => {
                const data_index = coverages.findIndex((coverage: string) => coverage === coverageDetails.id);
                if (data_index === -1) {
                    return <CoverageLabel variant="not-covered" name={coverageDetails.name} info={coverageDetails.info} key={index} />;
                } else {
                    return <CoverageLabel variant="covered" name={coverageDetails.name} info={coverageDetails.info} shape={coverageDetails.shape} key={index} />;
                }
            })}
        </ul>
    );
};

const PackageCarousel = ({ packages, selectedPackageIndex, loadingPrice, coverageDetailsList, onPackageChange, onPackageSubmit }: any) => {
    const [selectedIndex, setSelectedIndex, containerRef] = useSelectOnScrolled({
        selectedPackageIndex: selectedPackageIndex < packages.length ? selectedPackageIndex : 0,
    });

    useEffect(() => {
        setSelectedIndex(selectedPackageIndex < packages.length ? selectedPackageIndex : 0);
    }, [selectedPackageIndex]);

    const carouselItems = packages.map((package_data: PackageProps, index: number) => {
        const isActive = selectedIndex === index;
        return (
            <Box
                className={cx('flos-package-coverage', { 'active-package': isActive })}
                id={`flos-package-coverage-box-${index}`}
                key={index}
                data-index={index}
                onClick={() => {
                    setSelectedIndex(index);
                    onPackageChange(index);
                }}
            >
                <div className="flos-package-coverage-action">
                    <div className="flos-package-coverage-helpblock">{package_data.helpBlock}</div>
                    <span className="flos-package-coverage-radio">
                        <input type="radio" id={`package-selector-${index}`} className="visuallyhidden" checked={isActive} onChange={() => {}} />
                        <label htmlFor={`package-selector-${index}`} className={cx('flos-radio')}>
                            <div className="dot" />
                        </label>
                    </span>
                    <span>
                        <span className="flos-package-price">{loadingPrice ? <LoadingDots /> : <h6 className={'u-spacing-stack-s'}>{package_data.price}</h6>}</span>
                        <h3 className="u-spacing-stack-s">{package_data.name}</h3>
                    </span>
                </div>
                <CoverageDetails coverages={package_data.coveredCoverageIds} coverageDetailsList={coverageDetailsList} />
                {isActive && (
                    <Button
                        className=""
                        theme="primary"
                        onClick={(event) => {
                            event.stopPropagation();
                            onPackageSubmit(index);
                        }}
                    >
                        Vælg
                    </Button>
                )}
            </Box>
        );
    });
    return (
        <Carousel defaultIndex={selectedPackageIndex} ref={containerRef}>
            {carouselItems}
        </Carousel>
    );
};

const PackageSelector = ({ packages, defaultSelectedIndex = 0, loadingPrice = false, uniqueCoverageList, action, onChangeHandler, onSubmitHandler }: PackageSelectorProps) => {
    const onPackageChange = (packageId: any) => {
        onChangeHandler && onChangeHandler(packageId);
    };
    const onPackageSubmit = (packageId: any) => {
        onSubmitHandler && onSubmitHandler(packageId);
    };

    return (
        <div>
            <div className="flos-package">
                <div className="flos-package-info">
                    <div className="flos-package-info-action">{action}</div>
                    <div className="flos-package-info-legends">
                        <ul className="List">
                            {uniqueCoverageList.map((coverageDetails: Coverage, index: number) => (
                                <CoverageLabel name={coverageDetails.name} info={coverageDetails.info} key={index} />
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="flos-package-details">
                    {packages && (
                        <PackageCarousel
                            packages={packages}
                            loadingPrice={loadingPrice}
                            selectedPackageIndex={defaultSelectedIndex}
                            coverageDetailsList={uniqueCoverageList}
                            onPackageChange={onPackageChange}
                            onPackageSubmit={onPackageSubmit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export type { PackageSelectorProps, PackageProps };
export { PackageSelector };
export default PackageSelector;
