import React from 'react';
import { Base64 } from 'js-base64';
import { getServiceRejectionErr, getInfectedRejectionErr, isErrorExist } from './helpers';
import { getClientConfig, fetchFiles, uploadFile } from './upload-service';
import { IClientConfig, DEFAULT_ACCEPTED_ATTRS, IRequestHeader, IFileError, IFile, SCAN_STATUS } from './types';

/**
 * Contains custom hooks used by the upload service provider.
 * @exports useUploadClientConfig - a hook that manages the client configuration for the upload service provider.
 * @exports useFileState - a hook that manages the file state for the upload service provider.
 * @exports useUploadFile - a hook that manages the file upload for the upload service provider.
 */

/**
 * A custom hook that manages the client configuration for the upload service provider.
 * @param params - an object containing configuration parameters for the client.
 * @param params.maxUploadFileSizeInMb - the maximum size of a file that can be uploaded in megabytes.
 * @param params.minUploadFileSize - the minimum size of a file that can be uploaded in bytes.
 * @param params.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param params.requestHeaders - an object containing request headers to be sent with API requests.
 * @returns an array containing the following elements:
 *   - a list of errors that have occurred during client configuration.
 *   - a boolean indicating whether the client configuration is currently being loaded.
 *   - the client configuration object for the upload service provider.
 */
export const useUploadClientConfig = (params: {
    maxUploadFileSizeInMb: number; // in Mb
    minUploadFileSize: number; // in byte
    apiBaseUrl: string;
    requestHeaders: IRequestHeader;
}): [IFileError[] | [], boolean, IClientConfig, React.Dispatch<React.SetStateAction<IClientConfig>>] => {
    const { apiBaseUrl, requestHeaders, maxUploadFileSizeInMb = 20, minUploadFileSize = 32 } = params;
    const [clientConfig, setClientConfig] = React.useState<IClientConfig>({
        acceptedExtensions: DEFAULT_ACCEPTED_ATTRS,
        consumerBucket: '',
        friendlyName: '',
        maxUploadFileSizeInMb,
        minUploadFileSize,
    });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<[IFileError] | []>([]);
    const request = React.useCallback(async () => {
        setIsLoading(true);
        const data = await getClientConfig({
            url: `${apiBaseUrl}/upload/v1/config`,
            headers: { ...requestHeaders },
        })
            .catch((error: Error) => {
                setErrors([getServiceRejectionErr('', error.message, 'getClientConfig')]);
            })
            .finally(() => {
                setIsLoading(false);
            });
        if (!data) {
            setIsLoading(false);
            return;
        }
        const { consumerBucket, friendlyName } = data;
        const acceptedExtensions = Object.keys(data.supportedExtensions).map((ext) => `.${ext}`);
        setClientConfig((currConfig) => ({ ...currConfig, consumerBucket, friendlyName, acceptedExtensions }));
        setIsLoading(false);
    }, [requestHeaders, apiBaseUrl]);
    React.useEffect(() => {
        request();
    }, [request]);

    return [errors, isLoading, clientConfig, setClientConfig];
};

/**
 * A custom hook that manages the file state for the upload service provider.
 * @param params - an object containing configuration parameters for the hook.
 * @param params.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param params.requestHeaders - an object containing request headers to be sent with API requests.
 * @param params.files - an array of files to be managed by the hook.
 * @returns an array containing the following elements:
 *   - a list of errors that have occurred during file state management.
 *   - a boolean indicating whether the file state is currently being loaded.
 *   - an array of file objects that have been scanned for viruses.
 *   - a function to set the scanned file list.
 */
export const useFileState = (params: {
    files: IFile[];
    apiBaseUrl: string;
    requestHeaders: IRequestHeader;
}): [IFileError[] | [], boolean, IFile[], React.Dispatch<React.SetStateAction<IFile[]>>] => {
    const { apiBaseUrl, requestHeaders, files } = params;
    const [scanFileList, setScanFileList] = React.useState<IFile[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<IFileError[] | []>([]);
    const counter = React.useRef<number>(0);
    const delayInMiliseconds = 2000;

    const request = React.useCallback(async () => {
        if (!requestHeaders['x-top-task-id']) return;
        setIsLoading(true);

        const data = await fetchFiles({
            url: `${apiBaseUrl}/upload/v1/state`,
            headers: { ...requestHeaders },
        }).catch((error: Error) => {
            setErrors((currErrors) => {
                return [...currErrors, getServiceRejectionErr('', error.message, 'getFiles')];
            });
        });

        if (!data) {
            setIsLoading(false);
            return;
        }

        data.forEach((newFile: IFile) => {
            if (newFile.scanStatus === SCAN_STATUS.INFECTED) {
                setErrors((currErrors) => {
                    if (!isErrorExist(newFile.key, currErrors)) return currErrors;
                    return [...currErrors, getInfectedRejectionErr(newFile.key)];
                });
            }
        });
        setScanFileList(data);

        setIsLoading(false);
    }, [requestHeaders, apiBaseUrl]);

    React.useEffect(() => {
        let scanning = false;
        let timeoutId: number;
        scanning = scanFileList.some((file) => file.scanStatus === SCAN_STATUS.PENDING || file.scanStatus === SCAN_STATUS.SCANNING);
        if (scanning && counter.current < 30) {
            timeoutId = window.setTimeout(() => request(), delayInMiliseconds);
        }
        return () => {
            if (timeoutId) window.clearTimeout(timeoutId);
        };
    }, [scanFileList, request]);
    React.useEffect(() => {
        // make a initial request regardless
        request();
    }, [files, request]);

    return [errors, isLoading, scanFileList, setScanFileList];
};

/**
 * A custom hook that manages the upload state for the upload service provider.
 * @param params - an object containing configuration parameters for the hook.
 * @param params.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param params.requestHeaders - an object containing request headers to be sent with API requests.
 * @param params.files - an array of files to be uploaded.
 * @param props.metaData - extra info to attach together with the file uploaded
 * @returns an array containing the following elements:
 *   - a list of errors that have occurred during file upload.
 *   - a boolean indicating whether the files are currently being uploaded.
 *   - an array of files that have been uploaded to S3 bucket.
 *   - an array of files that have been rejected due to various reasons (e.g., file size too large).
 */
export const useUploadFile = ({
    apiBaseUrl,
    files,
    requestHeaders,
    metaData,
}: {
    apiBaseUrl: string;
    files: IFile[];
    requestHeaders: IRequestHeader;
    metaData?: { [key: string]: string };
}): [IFileError[] | [], boolean, IFile[], IFile[]] => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [uploadedFileList, setUploadedFileList] = React.useState<IFile[]>([]);
    const [rejectedFileList, setRejectedFileList] = React.useState<IFile[]>([]);
    const [errors, setErrors] = React.useState<IFileError[] | []>([]);
    const request = React.useCallback(
        async (newFiles: IFile[]) => {
            setIsLoading(true);
            const toUploadList: IFile[] = [];
            const toRejectList: IFile[] = [];
            const toShowErrors: IFileError[] = [];
            await Promise.allSettled(
                newFiles.map(async (file) => {
                    if (!file) return Promise.resolve();
                    return await uploadFile({
                        url: `${apiBaseUrl}/upload/v1/upload`,
                        data: {
                            fileName: file.key,
                            metadata: { encodedfilename: Base64.encode(file.key), ...metaData },
                        },
                        headers: { ...requestHeaders },
                        file: (file.$metaData as { [key: string]: string | Blob }).file as Blob,
                    })
                        .then(() => {
                            toUploadList.push(file);
                        })
                        .catch((error: Error) => {
                            toRejectList.push(file);
                            toShowErrors.push(getServiceRejectionErr(file.key, error.message, 'uploadFile'));
                        });
                })
            ).then(() => {
                // only fire everything when requests is completed
                // ? To prevent firing same amount of `/state` API when upload multiple files.
                if (toUploadList.length) {
                    setUploadedFileList((currUploadedFiles) => {
                        return [...currUploadedFiles, ...toUploadList];
                    });
                }
                if (toShowErrors.length) {
                    setErrors((currErrors) => {
                        return [...currErrors, ...toShowErrors];
                    });
                }
                if (toRejectList.length) {
                    setRejectedFileList((currRejectedFiles) => {
                        return [...currRejectedFiles, ...toRejectList];
                    });
                }
            });
            setIsLoading(false);
        },
        [apiBaseUrl, requestHeaders, metaData]
    );
    React.useEffect(() => {
        request(files);
    }, [files, request]);
    return [errors, isLoading, uploadedFileList, rejectedFileList];
};
