import React from 'react';
import cx from 'classnames';
import { CSS_STATUS_MAP, FileNameType, IFile } from './types';
import { FlosIcon, Button } from '../../..';

export type ScanFileListProps = {
    fileList: IFile[];
    onDelete: (fileName: FileNameType) => void;
    deleteButtonLabel: string;
    loading?: boolean;
};

export const ScanFileList: React.FC<ScanFileListProps> = ({ fileList, onDelete, deleteButtonLabel, loading = false }) => {
    const sortedFiles = fileList.sort((fileA, fileB) => fileA.key.localeCompare(fileB.key));
    const listRef = React.useRef<HTMLUListElement>(null);
    const [overflow, setOverflow] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!listRef.current) return;
        setOverflow(listRef.current.scrollHeight > listRef.current.clientHeight);
    }, [sortedFiles, listRef]);

    if (sortedFiles.length <= 0) return null;
    return (
        <ul data-testid="filelist" className={cx('flos-uploadfield-filelist', { 'flos-uploadfield-filelist--overflow': overflow })} ref={listRef}>
            {sortedFiles.map((file, index) => {
                const status = CSS_STATUS_MAP[file.scanStatus];
                const fileIsLoading = status === 'is-loading';

                return (
                    <li data-testid="file-wrapper" className="flos-uploadfield-file" key={`${file.key}-${index}`}>
                        {fileIsLoading && <FlosIcon className={'has-fill is-info'} shape={'notification'} size={24} />}
                        {!fileIsLoading && <FlosIcon shape="document" size={24} />}
                        <div className={cx('flos-uploadfield-filelabel', { [status]: !!status })} title={file.key}>
                            {status === 'has-error' && <div>[ERRROR]</div>}
                            <div className="flos-uploadfield-filename">{file.key}</div>
                        </div>
                        <Button
                            className="flos-uploadfield-deletebtn"
                            theme="ghost"
                            size="small"
                            disabled={loading || fileIsLoading}
                            onClick={(e) => {
                                e.preventDefault();
                                return onDelete(file.key);
                            }}
                        >
                            {deleteButtonLabel}
                        </Button>
                    </li>
                );
            })}
        </ul>
    );
};
