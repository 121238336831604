import React from 'react';
import cx from 'classnames';
type ButtonGroupProps = {
    stacked?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ stacked, children }: ButtonGroupProps) => {
    return <div className={cx('flos-button-group', { 'flos-button-group--stacked': stacked })}>{children}</div>;
};

export type { ButtonGroupProps };
