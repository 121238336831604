import React from 'react';
import cx from 'classnames';

type TableCellProps = React.TdHTMLAttributes<HTMLTableCellElement>;
type TableHeadCellProps = React.ThHTMLAttributes<HTMLTableCellElement> & TableCellProps;
type TableColGroupProps = React.ColgroupHTMLAttributes<HTMLTableColElement>;
type TableColProps = React.ColHTMLAttributes<HTMLTableColElement> & TableCellProps;
type TableRowProps = React.HTMLAttributes<HTMLTableRowElement>;
type TableSectionProps = React.HTMLAttributes<HTMLTableSectionElement>;
type TableProps = React.HTMLAttributes<HTMLTableElement> & {
    /** To indicate if table cells whitespace should be normal or nowrap, `true`: normal; `false`: nowrap */
    wrapped?: boolean;
    /** To control cell direction on mobile, do nothing when viewport beyond mobile */
    mobileDirection?: 'vertical' | 'horizontal';
};
type TableComponent = React.FC<TableProps> & {
    Cell: React.FC<TableCellProps>;
    HeadCell: React.FC<TableHeadCellProps>;
    ColGroup: React.FC<TableColGroupProps>;
    Col: React.FC<TableColProps>;
    Row: React.FC<TableRowProps>;
    Head: React.FC<TableSectionProps>;
    Body: React.FC<TableSectionProps>;
};

export const Table: TableComponent = ({ children, className, wrapped, mobileDirection = 'horizontal', ...props }) => {
    return (
        <div className={cx('flos-table-container', className)} {...props}>
            <div className={cx('flos-table-scroller')}>
                <table className={cx('flos-table', { ['flos-table--wrapped']: wrapped, [`flos-table--cell-${mobileDirection}`]: !!mobileDirection })}>{children}</table>
            </div>
        </div>
    );
};

/**
 * TableHead component representing the header of the table.
 * @param {object} props - Properties passed to the component.
 * @returns {JSX.Element}
 */
Table.Head = function TableHead({ children, className, ...props }) {
    return (
        <thead className={cx('flos-table-head', className)} {...props}>
            {children}
        </thead>
    );
};

/**
 * TableBody component representing the body of the table.
 * @param {object} props - Properties passed to the component.
 * @returns {JSX.Element}
 */
Table.Body = function TableBody({ children, className, ...props }) {
    return (
        <tbody className={cx('flos-table-body', className)} {...props}>
            {children}
        </tbody>
    );
};

/**
 * TableRow component representing a row in the table.
 * @param {object} props - Properties passed to the component.
 * @returns {JSX.Element}
 */
Table.Row = function TableRow({ children, className, ...props }) {
    return (
        <tr className={cx('flos-table-row', className)} {...props}>
            {children}
        </tr>
    );
};

/**
 * TableCell component representing a cell in the table.
 * @param {object} props - Properties passed to the component.
 * @param {React.ReactNode} props.children - Content inside the cell.
 * @returns {JSX.Element}
 */
Table.Cell = function TableCell({ children, className, ...props }) {
    return (
        <td className={cx('flos-table-cell', className)} {...props}>
            {children}
        </td>
    );
};

/**
 * TableHeadCell component representing a header cell in the table.
 * @param {object} props - Properties passed to the component.
 * @param {React.ReactNode} props.children - Content inside the header cell.
 * @returns {JSX.Element}
 */
Table.HeadCell = function TableHeadCell({ children, className, ...props }) {
    return (
        <th className={cx('flos-table-cell flos-table-cell--head', className)} {...props}>
            {children}
        </th>
    );
};

/**
 * TableCol component representing a col in the table.
 * @param {object} props - Properties passed to the component.
 * @returns {JSX.Element}
 */
Table.Col = function TableCol({ ...props }) {
    return <col {...props} />;
};

/**
 * TableColGroup component representing a colgroup in the table.
 * @param {object} props - Properties passed to the component.
 * @returns {JSX.Element}
 */
Table.ColGroup = function TableColGroup({ children, ...props }) {
    return <colgroup {...props}>{children}</colgroup>;
};

export default Table;
export type { TableProps, TableColProps, TableColGroupProps, TableRowProps, TableSectionProps, TableCellProps, TableHeadCellProps };
export type { ColumnDefinition } from './useTableData';
export { useTableData } from './useTableData';
