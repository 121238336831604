import React from 'react';
import cx from 'classnames';

type StreamerProps = {
    /** Url to fetch the image */
    imageUrl?: string;
    /** Position of the imaga */
    imagePosition?: 'left';
    /** Coloring theme */
    theme?: 'primary' | 'white';
    /** Size prop to adjust top and bottom padding - only to be used when no image */
    size?: 'small';
    /** Title */
    title: string;
    /** Text below title */
    text?: string;
    /** This label will show above the title */
    label?: string;
    /** Children will be rendered below text */
    children?: React.ReactNode;
    /** Area with CTA buttons or links */
    actionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

const Streamer = ({ imageUrl, theme, size, imagePosition, title, text, label, children, actionArea, className, ...props }: StreamerProps) => {
    return (
        <div className={cx('streamer', { [`streamer--${theme}`]: !!theme, [`streamer--${size}`]: !!size, [`streamer--${imagePosition}`]: !!imagePosition }, className)} {...props}>
            <div className={'container'}>
                <div className={cx('streamer-content', { 'streamer-no-image': !imageUrl, 'streamer-no-text': !text })}>
                    <div className="streamer-text">
                        {label && <div className="h5 streamer-label">{label}</div>}
                        {title && <div className={cx('h2', 'streamer-title', { 'u-spacing-stack-none': !text && !children })}>{title}</div>}
                        {text && <p className={cx({ 'u-spacing-stack-m': !!children })}>{text}</p>}
                        {children && <div className="streamer-children">{children}</div>}
                    </div>
                    {actionArea && <div className="streamer-action">{actionArea}</div>}
                </div>
                {imageUrl && (
                    <div className="streamer-image">
                        <img alt="" src={imageUrl} />
                    </div>
                )}
            </div>
        </div>
    );
};

export type { StreamerProps };
export { Streamer };
export default Streamer;
